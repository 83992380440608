.actions-container {
    position: fixed;
    bottom: 50px;
    right: 30px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    pointer-events: none;
}

.actions-container button, .actions-container a {
    pointer-events: auto;
}

.actions-container .tools-container .btn {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    cursor: pointer;
}

.actions-container .tools-container .btn:hover {
    cursor: pointer;
}

.actions-container .tools-container .btn:not(:last-child) {
    margin-bottom: 10px;
}

.actions-container .btn.disabled, .actions-container .btn[disabled] {
    opacity: 0.6;
    pointer-events: none;
    cursor: not-allowed;
}

.actions-container .tools-container {
    margin-bottom: 50px;
}

.actions-container .below-section {
    display: flex;
}

.actions-container .below-section .btn {
    margin-bottom: 0;
    margin-left: 15px;
}

.actions-container .below-section .btn i {
    margin-right: 10px;
}

.canvas-wrapper {
    z-index: 1;
    width: 100%;
    max-width: 900px;
}

.canvas-wrapper .lower-canvas, .canvas-wrapper .canvas-container {
    margin-bottom: 15px;
    background-color: white;
}

@media screen and (max-width: 768px) {
    .page-container {
        margin-top: 4rem;
        padding: 2rem;
    }
    .canvas-wrapper {
        margin: 0 30px;
    }

    .actions-container {
        bottom: 20px;
        right: 15px;
    }

    .actions-container .tools-container {
        margin-bottom: 30px;
    }

    .actions-container .tools-container .btn {
        width: 40px;
        height: 40px;
        font-size: 14px;
    }

    .actions-container .below-section .btn {
        font-size: 11px;
        padding: 0.5rem 1rem;
        margin-left: 5px;
    }
}
